
.button {
  background-color: transparent;
  border-width: 0px;
  border-color: transparent;
  font-family: 'Bree Serif';
  font-weight: bold;
  color: black;
  cursor: pointer;
  background-color: #ffffff;
  font-size: 20px;

}

.tabcontainer {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  margin-left: 10px;
}

.active-tab-btn {
  background-color: #FFB237;
  border-radius: 50px;
  font-family: 'Bree Serif';
  font-weight: bold;
  font-size: 20px;
  color: #ffffff;
  text-decoration: transparent;
  text-decoration-color: transparent;
  cursor: pointer;
}


