
.feedback-form {
  width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffc966;
  border-radius: 10px;
  font-family: Arial, sans-serif;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  
}

.form-header {
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  border: 2px dashed black;
  margin-bottom: 20px;
  position: relative;
}

.form-header h2 {
  margin: 0;
  color: #000;
  font-size: 24px;
  text-align: center;
}

.form-header p {
  margin: 5px 0;
  color: #333;
  font-size: 14px;
  text-align: center;
}

.website-link {
  color: #0000FF;
}

.company-logo {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ffc966;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
}

.form-body {
  display: flex;
  justify-content: space-between;
}

.personal-info {
  width: 70%;
}

.personal-info h3 {
  margin-top: 0;
  margin-bottom: 15px;
}

.input-group {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  
}

.input-label {
  width: 30%;
  font-weight: bold;
  color: #000;
}

.text-input,
.tel-input {
  width: 70%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;

}

.image-upload {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.image-upload-label {
  width: 150px;
  height: 150px;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
}

.upload-text {
  text-align: center;
  font-size: 14px;
}

.uploaded-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.review-area {
  margin-top: 20px;
}

.review-area h3 {
  margin-bottom: 10px;
}

.review-input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 3px;
  resize: vertical;
}

.signature-area {
  margin-top: 15px;
  text-align: right;
  font-weight: bold;
}

.submit-button {
  padding: 10px 20px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 15px;
}

.submit-button:hover {
  background-color: #333333;
}