/* Modal.css */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.modal-main {
  position: fixed;
  background: white;
  /* width: inherit; */
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}


@media screen and (max-width: 768px) {
.modal-main {
  position: fixed;
  background: white;
  width: inherit;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.responsive_card_here{
    width: fit-content;
}

}


@media screen and (min-width: 769px) {
  .resposive_recepit_container_here{
    padding: 30px;
    margin-left: 230px;
    margin-top: -320px;
  }

  .responsive_recepit_container_here{
    display: none;
  }
}