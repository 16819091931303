/* General styles */
.sidebar {
    width: 250px;
    transition: all 0.3s ease;
}

.sidebar_list {
    padding: 10px;
}

.metismenu li {
    list-style-type: none;
}

.metismenu li a {
    display: flex;
    align-items: center;
    padding: 10px;
    text-decoration: none;
    color: black;
}

/* Small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .sidebar {
        width: 100%;
        height: auto;
    }

    .metismenu li {
        display: flex;
        justify-content: center;
    }

    .metismenu li a {
        padding: 15px;
        font-size: 1.2em;
    }

    .sidebar_list {
        padding: 10px;
        margin-left: -45px;
    }

    
}

/* Medium devices (portrait tablets, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
    .sidebar {
        width: 200px;
    }

    .metismenu li a {
        padding: 12px;
    }
    
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .sidebar {
        width: 250px;
    }

    .metismenu li a {
        padding: 10px;
    }

    
}
