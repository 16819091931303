/* WhatsAppButton.css */

.whatsapp-button {
    position: fixed;
    bottom: 10px;
    left: 20px;
    background-color: #25d366; /* WhatsApp green */
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1000; /* Ensure it's above other content */
  }
  
  .whatsapp-icon {
    color: #fff;
    font-size: 30px;
  }
  