.google-map-one {
    position: relative;
    display: block;
    z-index: 1;
    background-color: var(--ontech-black);
}

.google-map-one__map {
    position: relative;
    display: block;
    border: none;
    height: 440px;
    width: 100%;
    mix-blend-mode: luminosity;
}

/*
=============================================
Contact Info One
=============================================
*/

.contact-info-one {
    position: relative;
    display: block;
    z-index: 5;
    margin-top: -70px;
}

.contact-info-one .container {
    position: static;
    max-width: 1380px;
    width: 100%;
    padding: 0px 15px;
    margin: 0 auto;
}

.contact-info-one__inner {
    position: relative;
    display: block;
    padding: 33px 85px 33px;
    margin-bottom: -60px;
    z-index: 5;
}

.contact-info-one__bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-attachment: scroll;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: -1;
}

.contact-info-one__single {
    position: relative;
}

@media (min-width:768px) {
    .contact-info-one__single{
        position: relative;
        display: flex;
        align-items: center;
    }
}

@media (max-width:767px){
    .responsive_wn{
        margin-left: -30px;
    }
}

.contact-info-one__single.ml75 {
    margin-left: 5px;
}

.contact-info-one__single.ml70 {
    margin-left: 60px;
}

.contact-info-one__single .icon-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background: #383838;
    border-radius: 50%;
    border: 3px solid #ffffff;
}

.contact-info-one__single .icon-box span::before {
    position: relative;
    display: inline-block;
    color: #ffffff;
    font-size: 20px;
}

.contact-info-one__single .content-box {
    position: relative;
    display: block;
    padding-left: 30px;
    flex: 1;
}

.contact-info-one__single .content-box p {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
    line-height: 26px;
}

.contact-info-one__single .content-box h3 {
    color: #383838;
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
}

.contact-info-one__single .content-box h3 a {
    color: #383838;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.contact-info-one__single .content-box h3 a:hover {
    color: var(--ontech-black);
}

/*
=============================================
Footer One
=============================================
*/

.footer-one {
    position: relative;
    display: block;
    z-index: 1;
}

.footer-one__bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-attachment: scroll;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: -1;
}

.footer-one .shape1 {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
}

.footer-one .shape2 {
    position: absolute;
    left: 160px;
    bottom: 110px;
    z-index: -1;
}

.footer-one .shape3 {
    position: absolute;
    right: 100px;
    bottom: 175px;
    z-index: -1;
}

.footer-one .shape4 {
    position: absolute;
    right: -20px;
    bottom: 0px;
    z-index: -1;
}

.footer-one__top {
    position: relative;
    display: block;
    padding: 120px 0px 10px;
}

.footer-one__single {
    position: relative;
    display: block;
}

.footer-one__single .title-box {
    position: relative;
    display: block;
    margin-bottom: 31px;
    margin-top: -8px;
}

.footer-one__single .title-box h3 {
    color: #ffffff;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    text-transform: capitalize;
}

.footer-one__about {
    position: relative;
    display: block;
    max-width: 220px;
    width: 100%;
}

.footer-one__about-logo {
    position: relative;
    display: block;
}

.footer-one__about-logo a {
    position: relative;
    display: inline-block;
}

.footer-one__about-logo a img {
    width: 100%;
}

.footer-one__about-text {
    position: relative;
    display: block;
    margin-top: 35px;
}

.footer-one__about-text p {
    color: #ffffff;
}

.footer-one__post {
    position: relative;
    display: block;
    margin-left: 70px;
    max-width: 325px;
    width: 100%;
}

@media (max-width: 567px){
    .footer-one__post{
        margin-left: 0px;
    }
}

.footer-one__post-box {
    position: relative;
    display: block;
}

.footer-one__post-list {
    position: relative;
    display: block;
    overflow: hidden;
}

.footer-one__post-list li {
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #423c2d;
    padding-bottom: 24px;
    margin-bottom: 24px;
}

.footer-one__post-list li:last-child {
    margin-bottom: 0;
}

.footer-one__post-list li .img-box {
    position: relative;
    display: block;
    width: 80px;
    border-radius: 3px;
    overflow: hidden;
}

.footer-one__post-list li .img-box img {
    width: 100%;
}

.footer-one__post-list li .content-box {
    position: relative;
    display: block;
    flex: 1;
    margin-left: 20px;
    margin-top: 2px;
}

.footer-one__post-list li .content-box p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 6px;
}

.footer-one__post-list li .content-box p span::before {
    position: relative;
    display: inline-block;
    color: rgba(255, 255, 255, 0.5);
    font-size: 15px;
    top: 1px;
    padding-right: 3px;
}

.footer-one__post-list li .content-box h4 {
    font-size: 16px;
    line-height: 30px;
    font-weight: 600;
    font-family: var(--ontech-font);
}

.footer-one__post-list li .content-box h4 a {
    color: #ffffff;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.footer-one__post-list li .content-box h4 a:hover {
    color: var(--ontech-base);
}

.footer-one__links {
    position: relative;
    display: block;
}

@media (min-width: 576px){
    .footer-one__links{
        margin-left: -40px;
    }
}

.footer-one__links-box {
    position: relative;
    display: block;
    margin-top: -4px;
}

.footer-one__links-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin-top: -30px;
}

.footer-one__links-list li {
    position: relative;
    display: block;
    margin-bottom: 10px;
}

.footer-one__links-list li:last-child {
    margin-bottom: 0;
}

.footer-one__links-list li a {
    color: rgba(255, 255, 255, 0.6);
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.footer-one__links-list li a:hover {
    color: var(--ontech-base);
}

.footer-one__newsletter {
    position: relative;
    display: block;
}

.footer-one__newsletter-box {
    position: relative;
    display: block;
}

.footer-one__newsletter-text1 {
    position: relative;
    display: block;
    margin-bottom: 27px;
}

.footer-one__newsletter-text1 p {
    color: #ffffff;
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
}

.footer-one__newsletter-form {
    position: relative;
    display: block;
}

.footer-one__newsletter-form-input-box {
    position: relative;
    display: block;
}

.footer-one__newsletter-form-input-box input[type="email"] {
    color: #848484;
    font-size: 15px;
    height: 60px;
    width: 100%;
    font-weight: 400;
    padding-left: 45px;
    padding-right: 30px;
    background: #ffffff;
    border-radius: 3px;
    outline: none;
    border: none;
}

.footer-one__newsletter-form-input-box .icon {
    position: absolute;
    top: 16px;
    left: 15px;
    font-size: 20px;
    color: #e6e6e6;
}

.footer-one__newsletter-form-input-box .icon span::before {
    position: relative;
    display: inline-block;
    font-weight: 400;
    top: 1px;
}

.footer-one__newsletter-btn {
    position: relative;
    display: block;
    margin-top: 10px;
}

/* .footer-one__newsletter-btn .thm-btn {
    text-transform: capitalize;
    font-weight: 300;
    width: 100%;
} */

.footer-one__newsletter-btn .thm-btn {
    text-transform: capitalize;
    font-weight: 300;
    width: 50%;
    padding: 0px ;/* Adjust the padding as needed */
    font-size: 14px; 
    height: 60px;
    text-align: center;/* Optional: Adjust the font size for a smaller button */
}

.footer-one__newsletter-text2 {
    position: relative;
    display: block;
    margin-top: 25px;
}

.footer-one__newsletter-text2 p {
    color: rgba(255, 255, 255, 0.66);
}

.footer-one__bottom {
    position: relative;
    display: block;
}

.footer-one__bottom-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #332a12;
    padding: 20px 0px 20px;
}

.footer-one__bottom-left {
    position: relative;
    display: flex;
    align-items: center;
}

.footer-one__bottom-left .title-box {
    position: relative;
    display: block;
}

.footer-one__bottom-left .title-box p {
    color: #ffffff;
    font-weight: 500;
}

.footer-one__bottom-left .social-links {
    position: relative;
    display: block;
    margin-left: 15px;
}

.footer-one__bottom-left .social-links ul {
    position: relative;
    display: block;
}

.footer-one__bottom-left .social-links ul li {
    position: relative;
    display: inline-block;
    margin-right: 6px;
}

.footer-one__bottom-left .social-links ul li:last-child {
    margin-right: 0;
}

.footer-one__bottom-left .social-links ul li a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--ontech-black);
    font-size: 15px;
    width: 35px;
    height: 35px;
    background: #ffffff;
    border-radius: 50%;
    transition: all 200ms linear;
    transition-delay: 0.1s;
    z-index: 1;
}

.footer-one__bottom-left .social-links ul li a:hover {
    color: #ffffff;
}

.footer-one__bottom-left .social-links ul li a::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: var(--ontech-base);
    border-radius: 50%;
    transform: scale(0.5);
    opacity: 0;
    -webkit-transition: all 0.4s linear;
    -o-transition: all 0.4s linear;
    transition: all 0.4s linear;
    z-index: -1;
}

.footer-one__bottom-left .social-links ul li a:hover::before {
    transform: scale(1.0);
    opacity: 1;
}

.footer-one__bottom-right {
    position: relative;
    display: block;
}

.footer-one__bottom-right p {
    color: rgba(255, 255, 255, 0.8);
}

.footer-one__bottom-right p a {
    color: rgba(255, 255, 255, 0.8);
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.footer-one__bottom-right p a:hover {
    color: var(--ontech-base);
}


@media (max-width: 567px){
    .footer-one__bottom-inner{
        display: flex;
        flex-direction: column;
    }

}

