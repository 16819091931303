/* General container for the job list */
.job-list-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.job-list h2 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 30px;
    color: #333;
    text-align: justify;
}

.job-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}

.job-item {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    margin-top: 30px;
}

.job-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.job-item h3 {
    font-size: 25px;
    margin-bottom: 10px;
    color: #ffb237;
}

.job-item p {
    margin: 10px 0;
    color: #555;
    line-height: 1.5;
}

.job-item p strong {
    color: #333;
}

.job-item-buttons {
    text-align: center;
    margin-top: 20px;
}

.button_22 {
    background-color: #ffb237;
    width: 80%;
    border: none;
    color: white;
    padding: 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button_22:hover {
    background-color: #ff9f00;
}
.icon-wrapper {
    background-color: #f4af23;
    position: relative;
    margin: auto;
    font-size: 20px;
    height: 2.5em;
    width: 2.5em;
    color: #ffffff;
    border-radius: 50%;
    display: grid;
    place-items: center;
    transition: 0.5s;
    z-index: 3;
  }

@media (max-width: 768px) {
    .job-list {
        grid-template-columns: 1fr;
    }
}


.card_carrer_container{
    border: #1f194c 2px solid;
    width: 100%;
    padding: 4px;
    margin-top: 30px;
    height: 400px;
    
 }

 .card_sub_container{
    background-color: rgb(43, 37, 37);
    background-size: cover;
    height: 100%;
    /* width: 100%; */
    color: white;
    padding-top: 5px;
    padding-left: 14px;
    text-align: center;
 }