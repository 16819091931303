.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-align: center;
  gap: 20px; /* Adjust gap between cards */
}

.cookieCard, .cookieCard1 {
  width: 90%;
  max-width: 300px;
  height: 200px;
  background: linear-gradient(to right, rgb(56, 56, 56), rgb(56, 56, 56));
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  position: relative;
  overflow: hidden;
  flex: 1;
  margin-right: -15px;
}

.cookieCard::before, .cookieCard1::before {
  width: 150px;
  height: 150px;
  content: "";
  background: linear-gradient(to right, rgb(221, 216, 216), rgb(243, 241, 241));
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  right: -25%;
  top: -25%;
}

.cookieDescription {
  font-size: 1.5em;
  color: rgb(241, 241, 241);
  z-index: 2;
}

.acceptButton {
  padding: 10px 20px;
  background-color: #ffb237;
  transition-duration: 0.2s;
  border: none;
  color: rgb(241, 241, 241);
  cursor: pointer;
  font-weight: 600;
  z-index: 2;
}

.acceptButton:hover {
  background-color: #e8d6bf;
  transition-duration: 0.2s;
}

/* Media Queries */
@media (min-width: 600px) {
  .dashboard {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .cookieCard, .cookieCard1 {
    width: 45%;
    max-width: 300px;
    margin-top: -290px;
  }
}

@media (min-width: 900px) {
  .dashboard {
    justify-content: center;
  }

 
}
