/* StudentDetails.css */

/* Common styles */
/*  */

.student-details-container {
  display: flex;
  margin: 20px;
}

.student-details-content {
  flex: 1;
  margin-left: 20px;
  position: relative;
}

.logo {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 100px;
  height: auto;
}

.details-box {
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.detail {
  margin-bottom: 10px;
}

.right-side-container {
  position: absolute;
  top: 50px;
  right: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.button-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #FFB237;
  color: #fff;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .student-details-container {
    flex-direction: column;
    margin: 10px;
  }

  .student-details-content {
    margin-left: 0;
  }

  .right-side-container {
    position: static;
    align-items: center;
  }

  .button-container {
    flex-direction: column;
    gap: 5px;
  }
}
