.applicant-list-container {
  margin-top: 20px;
  padding: 20px;
}

.applicant-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.applicant-table th,
.applicant-table td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.applicant-table th {
  background-color: #f2f2f2;
}

.applicant-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.applicant-table tr:hover {
  background-color: #f1f1f1;
}

.resume-download {
  padding: 5px 10px;
  background-color: #ffb237;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.resume-download:hover {
  background-color:#ffb237;
}

.delete-button {
  padding: 5px 10px;
  background-color:#ffb237;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.delete-button:hover {
  background-color:#ffb237;
}

/* Media Queries */
@media screen and (min-width: 769px) {
  .applicant-table {
    width: inherit;
    border-collapse: collapse;
    margin-top: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    margin-left: 300px;
    margin-bottom: 160px;
    margin-top: -270px;
  }


}

@media (max-width: 768px) {
  .card {
    width: calc(50% - 20px);
  }

  .applicantlist_container_main{
     width: 100%;
     margin-top: 70px;
     padding: 10px;
  }

}
@media (max-width: 768px) {
  .tr{
    margin-left: -300px;
        width: 350px;
    }
  }



@media (max-width: 480px) {
  .card {
    width: 100%;
  }
}