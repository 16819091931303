/* .certificate-view {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; 
    margin-top: 30px;
  }
  
  .card {
   
    background-color: transparent;
    
    width: fit-content;
    height: 100px;
    
  }
  
  .card h3 {
    color: #333;
    margin-bottom: 10px;
  }
  
  .card p {
    margin: 5px 0;
    color: #666;
  }
  
  @media (max-width: 768px) {
    .card {
      width: calc(50% - 20px); 
    }
  }
  
  @media (max-width: 480px) {
    .card {
      width: 100%; 
    }
  }

.pagination ul {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.pagination ul li {
  cursor: pointer;
  padding: 5px 10px;
  margin: 0 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.pagination ul li.active {
  background-color: #ffb237;
  color: #fff;
}

.edit-btn, .delete-btn {
  margin-right: 5px;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  border-radius: 3px;
}

.edit-btn {
  background-color: #4CAF50;
  color: white;
}

.delete-btn {
  background-color: #f44336;
  color: white;
}

.edit-btn:hover {
  background-color: #45a049;
}

.delete-btn:hover {
  background-color: #d32f2f;
} 
.search-input {
  padding: 10px;
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 4px;
  border-color: #007bff;
  
}

button {
  margin-right: 5px;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}
.action-buttons {
  display: flex;
  flex-direction: column; 
}



.action-buttons button {
  margin-bottom: 5px;
  padding: 8px 12px;
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}


.action-buttons button.edit-btn {
  background-color: black;
}

.action-buttons button.delete-btn {
  background-color: black;
}

.action-buttons button:hover {
  background-color: #ffb237;
}

.action-buttons button.delete-btn:hover {
  background-color: #db4437;
}

.action-buttons button.print-btn {
  background-color: black;
}

.action-buttons button.print-btn:hover {
  background-color: #5d4037;
}


.edit-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 500px;
  max-width: 90%;
}

.edit-modal-overlay {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.edit-form {
  display: flex;
  flex-direction: column;
}

.edit-form label {
  margin-bottom: 10px;
}

.edit-form input,
.edit-form select {
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.edit-form button {
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.edit-form button[type="submit"] {
  background-color: #ffb237;
  color: white;
}

.edit-form button[type="button"] {
  background-color: #ffb237;
  color: white;
}
.form-actions {
  display: flex;
  justify-content: flex-start;
  margin-top: -30px;
} */


/* CSS file: CertificateView.css or certi.css */
/*new */

/* CSS file: CertificateView.css or certi.css */

/* Container to place the input fields on the same line */
/* CSS file: CertificateView.css or certi.css */

/* Container to place the input fields on the same line */
.grade-date-container {
  display: flex;
  flex-direction: row;
  gap: 10px; /* Adjust the spacing between the input fields as needed */
}

/* Styles for input fields */
.grade-input,
.date-input {
  color: #000; /* Black color for text inside the input fields */
  border: 1px solid #000; /* Black border for the input fields */
  padding: 8px; /* Adjust padding as needed */
  border-radius: 4px; /* Optional: Rounded corners for a smoother look */
}

/* Styles for placeholder text */
.grade-input::placeholder,
.date-input::placeholder {
  color: #000; /* Black color for placeholder text */
}


/* CSS file: CertificateView.css or certi.css */

.grade-input::placeholder,
.date-input::placeholder {
  color: #000; /* Black color for placeholder text */
}

/* If you want to ensure the input fields have black text color as well */
.grade-input,
.date-input {
  color: #000; /* Black color for text inside the input fields */
}

.search-bar {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  color: #000; /* Black text color */
}

.student-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #fff; /* White background color */
}

.student-details p {
  margin: 0;
  color: #000; /* Black text color */
}

.actions button {
  margin-right: 10px;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.generate-btn {
  background-color: #4CAF50; /* Green */
  color: #fff; /* White text color */
}

.delete-btn {
  background-color: #f44336; /* Red */
  color: #fff; /* White text color */
}

.save-btn {
  background-color: #008CBA; /* Blue */
  color: #fff; /* White text color */
}

.grade-input,
.date-input {
  /* width: calc(50% - 10px); */
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  color: #000; /* Black text color */
}

.date-input {
  width: calc(50% - 10px);
}
/*new */

.responsive_all_here {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  margin-left: 250px;
  margin-top: -350px;
}

.search-bar {
  margin-bottom: 20px;
  max-width: 300px;
}

.search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.student-list {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.student-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
}

.student-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.student-info h3 {
  margin: 0 0 10px;
  color: #333;
  font-size: 1.2em;
}

.student-info p {
  margin: 5px 0;
  color: #666;
  font-size: 0.9em;
}

.student-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
}

.action-buttons {
  display: flex;
  gap: 10px;
}

.btn {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 0.9em;
}

.btn-generate {
  background-color: #ffb237;
  color: white;
}

.btn-delete {
  background-color: #f44336;
  color: white;
}
.res-input-grade{
width:100px;
}

.btn-save {
  background-color: #1a1666;
  color: white;
}
.btn-generate:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
.grade-input {
  display: flex;
  gap: 10px;
}

.input-grade,
.input-date {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9em;
}

.pagination-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.pagination {
  display: flex;
  list-style-type: none;
  padding: 0;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #333;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.page-item.active .page-link {
  background-color: #2196f3;
  color: white;
  border-color: #2196f3;
}

@media (max-width: 768px) {
  .responsive_all_here {
    margin-left: 0;
    margin-top: 0;
  }

  .search-bar {
    max-width: 100%;
    margin-left: -220px;
  }

  .student-list {
    grid-template-columns: 1fr;
  }

  .student-card {
    padding: 15px;
  }

  .student-actions {
    align-items: stretch;
  }

  .action-buttons {
    justify-content: space-between;
  }

  .btn,
  .input-grade,
  .input-date {
    width: 100%;
  }
}
