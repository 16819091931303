/* Write your CSS here */
.appcontainer {
  background-color: white;
  background-size: cover;
  height: 100%;
  width: 100%;
  margin-bottom: 40px;
  margin-top: 70px;
  margin-left: 0px;
}

.cardcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.heading {
  font-family: 'Bree Serif';
  font-size: 34px;
  color: #1e293b;
}

.search-input-container {
  /* display: flex;
  align-items: center;
  height: 40px;
  background-color: transparent;
  width: 100%;
  max-width: 250px;
  border-radius: 4px;
  border: 1px solid #7b8794;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 16px;
  color: black; */

  width: 100%;
  max-width: 220px;
  height: 45px;
  display: flex;
  padding: 12px;
  border-radius: 12px;
  border: 1.5px solid transparent;
  outline: none;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  box-shadow: 0px 0px 20px -18px;
}



.search-input {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  border: none;
  outline: none;
  background-color: transparent;
  color: #1e293b;
  flex-grow: 1;
}

.upcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.searchplaceholder {
  color: #7b8794;
}

.search_icon {
  height: 23px;
  width: 23px;
  margin-right: 9px;
}

.tabitemcontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.appcontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 568px){
  .tabitemcontainer{
      margin-left: -30px;
  }

  .appcontainer{
    margin-left: -0px;
  }
}
