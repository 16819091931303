.navigation-bar {
  width: 200px; /* Set your desired width */
  overflow-x: hidden; /* Hide horizontal overflow */
  overflow-y: auto; /* Enable vertical scroll */
}

.scrollable-links {
  display: flex;
  flex-direction: column;
}

.scrollable-links a {
  padding: 10px;
  text-decoration: none;
  color: #000; /* Set your desired text color */
  border-bottom: 1px solid #ccc; /* Add a border between links */
}

.scrollable-links a:hover {
  background-color: #f0f0f0; /* Set the background color on hover */
}

.demo-option-wrapper {
  height: 100%;
  overflow-y: auto;
  padding-bottom: 55px;
}

.demo-option-wrapper .demo-panel-header {
  padding: 40px 30px 30px;
  text-align: center;
}

.demo-option-container {
  position: fixed;
  top: 0;
  left: 100%;
  background: #fff;
  height: 100%;
  width: 350px;
  max-width: calc(100% - 45px);
  z-index: 100000;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.demo-option-container.open {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-box-shadow: -3px 0 50px -2px rgba(0, 0, 0, 0.14);
  box-shadow: -3px 0 50px -2px rgba(0, 0, 0, 0.14);
}

.demo-quick-option-list {
  padding: 27px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.demo-quick-option-list a img {
  border-radius: 5px;
}

.demo-quick-option-list a:hover img {
  -webkit-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  transform: translateY(-3px);
  -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.aeroland__toolbar .inner a {
  margin-top: 126px;
  display: block;
  width: 47px;
  height: 56px;
  line-height: 44px;
  color: #222;
  font-size: 20px;
  font-weight: 600;
  transform: rotate(-90deg);
  display: block;
  white-space: nowrap;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.aeroland__toolbar .inner {
  position: absolute;
  top: 380px;
  right: 100%;
  display: block;
  padding-top: 13px;
  width: 45px;
  height: 40px;
  border: 1px solid #eee;
  border-right: 0;
  border-radius: 5px 0 0 5px;
  background: #ffb237 !important;
  color: #000000;

  height: 120px;

  -webkit-box-shadow: -3px 0 10px -2px rgba(0, 0, 0, 0.1);
  box-shadow: -3px 0 10px -2px rgba(0, 0, 0, 0.1);
}
/* .aeroland__toolbar .inner {
    position: absolute;
    top: 200px;
    right: 100%;
    display: block;
    width: 40px;
    border: 1px solid #eee;
    border-right: 0;
    border-radius: 5px 0 0 5px;
    background: #fff;
    text-align: center;
    -webkit-box-shadow: -3px 0 10px -2px rgba(0, 0, 0, 0.1);
    box-shadow: -3px 0 10px -2px rgba(0, 0, 0, 0.1);
}

.aeroland__toolbar .inner a {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #222;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
} */
.business-solution-form-wrap {
  max-width: 400px;
  margin-left: auto;
  background: #fff;
  -webkit-box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1);
  padding: 42px 30px 51px;
  border-radius: 5px;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .business-solution-form-wrap {
    margin: auto;
  }
}
.service-hero-text .service-solution-form-wrap {
  width: 600px;
  max-width: 100%;
  margin: 0 auto;
}

.service-hero-text .service-solution-form-wrap select {
  border-color: #ec8437;
  background-color: #ec8437;
  background-image: url("");
  color: #fff;
  font-weight: 500;
  padding: 3px 30px;
  height: 60px;
}

.service-hero-text .service-solution-form-wrap select:focus {
  background: #ec8437 url("") no-repeat center right 20px !important;
}

.contact-title .sub-title {
  font-size: 18px;
}

.contact-input {
  margin: 0 -10px;
}

.contact-input .contact-inner {
  float: left;
  margin: 0 0 20px;
  padding: 0 10px;
  width: 50%;
}

@media only screen and (max-width: 575px) {
  .contact-input .contact-inner {
    width: 100%;
  }
}

.contact-inner {
  margin: 0 0 20px;
}

.contact-inner input,
.contact-inner textarea {
  width: 100%;
  color: #000;
  border-color: #f8f8f8;
  background-color: #f8f8f8;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
  border: none;
  width: 100%;
  outline: none;
  border: 1px solid #eee;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-radius: 5px;
  padding: 3px 20px;
  height: 56px;
}

.contact-inner textarea {
  height: 150px;
  padding: 15px;
}

.contact-inner select {
  color: #777;
}

.infotechno-contact-us-bg {
  background-color: #eff2f6;
  background-image: url();
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .conact-us-wrap-one {
    margin-bottom: 30px;
  }
}

.conact-us-wrap-one .heading {
  position: relative;
  padding-left: 34px;
  font-weight: 600;
  line-height: 1.4;
}

.conact-us-wrap-one .heading::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 4px;
  height: 94%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #d2a98e;
}

.conact-us-wrap-one .sub-heading {
  margin-top: 15px;
  font-size: 18px;
  margin-left: 34px;
  color: #696969;
}

.contact-info-one .icon,
.contact-info-two .icon,
.contact-info-three .icon {
  font-size: 40px;
  color: #ec8437;
}

.contact-info-one .heading,
.contact-info-two .heading,
.contact-info-three .heading {
  font-size: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 10px;
  margin-top: 10px;
}

.contact-info-one .call-us,
.contact-info-two .call-us,
.contact-info-three .call-us {
  line-height: 1.17;
  color: #002fa6;
  position: relative;
  display: inline-block;
}

.contact-info-one .call-us::before,
.contact-info-two .call-us::before,
.contact-info-three .call-us::before {
  content: "";
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: 1;
  -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  -o-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background: currentColor;
}

.contact-info-one .call-us:hover,
.contact-info-two .call-us:hover,
.contact-info-three .call-us:hover {
  color: #002fa6;
}

.contact-info-one .call-us:hover::before,
.contact-info-two .call-us:hover::before,
.contact-info-three .call-us:hover::before {
  width: 100%;
  left: 0;
  right: auto;
}

.contact-info-one .call-us a:hover,
.contact-info-two .call-us a:hover,
.contact-info-three .call-us a:hover {
  color: #002fa6;
}

.contact-info-one .btn--secondary,
.contact-info-two .btn--secondary,
.contact-info-three .btn--secondary {
  min-width: 230px;
}

.contact-info-one .btn--secondary:hover,
.contact-info-two .btn--secondary:hover,
.contact-info-three .btn--secondary:hover {
  background: #ec8437;
}

.contact-info-two .icon {
  color: #fff;
}

.contact-info-two .heading {
  color: rgba(255, 255, 255, 0.8);
}

.contact-info-two .call-us {
  color: #d2a98e;
}

.contact-info-two .call-us:hover {
  color: #d2a98e;
}

.contact-info-two .call-us a:hover {
  color: #d2a98e;
}

.contact-info-two .btn--secondary {
  background: #ffffff;
}
.contact-form__two input[type="text"]:focus,
.contact-form__two input[type="email"]:focus,
.contact-form__two input[type="url"]:focus,
.contact-form__two input[type="password"]:focus,
.contact-form__two input[type="search"]:focus,
.contact-form__two input[type="number"]:focus,
.contact-form__two input[type="tel"]:focus,
.contact-form__two input[type="range"]:focus,
.contact-form__two input[type="date"]:focus,
.contact-form__two input[type="month"]:focus,
.contact-form__two input[type="week"]:focus,
.contact-form__two input[type="time"]:focus,
.contact-form__two input[type="datetime"]:focus,
.contact-form__two input[type="datetime-local"]:focus,
.contact-form__two input[type="color"]:focus,
.contact-form__two textarea:focus,
.contact-form__two select:focus,
.contact-form__two select:focus,
.contact-form__two textarea:focus {
  color: #222;
  border-color: transparent;
  background: #fff;
  -webkit-box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
  box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
}

.contact-form__two select:focus {
  background: #fff url("") no-repeat center right 20px !important;
}

.appointment-contact-bg {
  background: url();
  background-repeat: no-repeat;
  background-position: center right;
}

.service-contact-bg {
  background-color: #002fa6;
  background-image: url();
  background-repeat: no-repeat;
  background-position: left bottom;
}

.processing-contact-us-bg {
  background-color: #002fa6;
  background-image: url();
  background-repeat: no-repeat;
  background-position: top right;
}

.page-oppen-off-sidebar {
  right: 0;
}

.page-oppen-off-sidebar__inner {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  width: 400px;
  float: right;
  background: #ffffff;
}

@media only screen and (max-width: 479px) {
  .page-oppen-off-sidebar__inner {
    width: 300px;
  }
}

.page-oppen-off-sidebar.active .page-oppen-off-sidebar__inner {
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}

.page-oppen-off-sidebar__header {
  background: #ffffff;
  padding: 15px 35px;
}

.page-oppen-off-sidebar .off-sidebar-contact-info--list .item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
}

.page-oppen-off-sidebar .off-sidebar-contact-info--list .item .icon {
  color: #6d70a6;
  min-width: calc(1em + 18px);
  font-size: 16px;
}

.page-oppen-off-sidebar .off-sidebar-contact-info--list .item a:hover {
  color: #38cb89;
}

.page-oppen-off-sidebar .off-sidebar-contact-info .off-sidebar-widget__list {
  overflow: hidden;
}

.page-oppen-off-sidebar .off-sidebar-contact-info .off-sidebar-widget__list li {
  width: 50%;
  float: left;
  margin-bottom: 8px;
}

.page-oppen-off-sidebar
  .off-sidebar-contact-info
  .off-sidebar-widget__list
  li
  a {
  line-height: 17px;
}
.comment-list .children li + li {
  margin-top: 35px;
}

.comment-list .comment-actions {
  margin-top: 16px;
  margin-top: 16px;
  color: #ababab;
}

.comment-list .comment-actions .comment-datetime {
  display: inline-block;
  margin-right: 10px;
}

.comment-list .comment-actions .comment-reply-link {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 700;
}

.comment-submit-btn .ht-btn {
  padding: 0 54px;
}

.post-feature-details.post-quote {
  position: relative;
  padding: 48px 40px;
  background: #5945e6;
  text-align: center;
}
.post-feature-details.post-link a {
  color: #38cb89;
  word-wrap: anywhere;
  font-weight: 400;
}

.post-feature-details.post-link::before {
  display: none;
}

.peatures_image-wrap {
  margin-right: -168px !important;
  margin-left: 80px !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .peatures_image-wrap {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .peatures_image-wrap {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .peatures_image-wrap {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 767px) {
  .peatures_image-wrap {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}
.page-oppen-off-sidebar .off-sidebar-contact-info .off-sidebar-widget__list {
  overflow: hidden;
}

.page-oppen-off-sidebar .off-sidebar-contact-info .off-sidebar-widget__list li {
  width: 50%;
  float: left;
  margin-bottom: 8px;
}

.page-oppen-off-sidebar
  .off-sidebar-contact-info
  .off-sidebar-widget__list
  li
  a {
  line-height: 17px;
}
