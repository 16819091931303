
@media screen and (min-width: 768px) {
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-left: 300px;
    margin-top: -330px;
  }
}

.student-form {
  width: 100%;
  max-width: 1200px;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15px;
}

.input-group {
  flex: 1 1 22%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin: 5px;
}

.input-group label {
  margin-bottom: 5px;
  font-weight: bold;
  text-align: left;
}

.input-group input,
.input-group select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
}

.submit-group{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

button[type="submit"] {
  padding: 10px 20px;
  background-color: #FFB237;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #FFB237;
}

.link-container {
  margin-top: 20px;
}

.link-container a {
  color: #007bff;
  text-decoration: none;
}

.link-container a:hover {
  text-decoration: underline;
}

@media (max-width: 1200px) {
  .input-group {
    flex: 1 1 48%;
  }
}

@media (max-width: 768px) {
  .input-group {
    flex: 1 1 100%;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
  }
}

@media (max-width: 600px) {
  .form-grid {
    flex-direction: column;
  }

  .input-group {
    flex: 1 1 100%;
  }
}
