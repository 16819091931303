Styles for the CertificateDetaile component
.edit-form {
    max-width: 700px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .edit-form h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .edit-form .form-row {
    display: flex;
    margin-bottom: 15px;
  }
  
  .edit-form .form-group {
    flex: 1;
    margin-right: 10px;
  }
  
  .edit-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  
  .edit-form input,
  .edit-form select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #ffebcd; /* Light orange background */
    color: black; /* Text color in black */
  }
  
  .edit-form button {
    width: 120px; /* Width of 120px */
    padding: 10px;
    margin-top: 10px;
    background-color: #4caf50; /* Green background */
    color: black; /* Text color in black */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px; /* Font size */
  }
  
  .edit-form button[type="button"] {
    width: 120px; /* Width of 120px */
    background-color: #f44336; /* Red background for the cancel button */
  }
  
  .edit-form button:hover {
    background-color: #45a049; /* Darker green on hover */
  }
  
  .edit-form button[type="button"]:hover {
    background-color: #e53935; /* Darker red on hover */
  }
  
  .edit-form .button-container {
    margin-top: 20px;
  }
  