/* ApplyJobForm.css */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the popup is above all other content */
}

.popup-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1001; /* Ensure the content itself is above the overlay */
    position: relative; /* For positioning the close button */
}

.form-group {
    margin-bottom: 15px;
    width: 100%;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="file"],
.form-group input[type="number"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    margin-top: 5px;
    color: black;
}

button[type="submit"],
.btn-secondary {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 12px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
    margin-top: 10px;
    display: block;
}

button[type="submit"]:hover {
    background-color: #FFB237;
}

.btn-secondary {
    background-color: #6c757d;
    margin-top: 10px;
}

.btn-secondary:hover {
    background-color: #5a6268;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #aaa;
}

.close-button:hover {
    color: #000;
}
