/* Write your CSS here */
.appItemcontainer {
    background-color: white;
    background-size: cover;
    border-radius: 7px;
    text-align: center;
    padding: 10px;
    height: 120px;
    width: 140px;
    margin: 10px;
  }
  
  .appimage {
    height: 135px;
    width: 195px;
  }
  
  .appname {
    font-family: 'Bree Serif';
    color: #1e293b;
  }

  /* END OLD CODE */

  .card_1 {
    width: 260px;
    height: 254px;
    position: relative;
    border-radius: 40px;
    transition: all 0.8s;
    perspective: 600px;
    perspective-origin: center bottom;
    margin: 20px;
  }
  
  .upper-part_1 {
    width: 100%;
    height: 65%;
    border-radius: 40px 40px 0 0;
    position: relative;
    transform-style: preserve-3d;
    transition: all 0.9s;
  }
  
  .upper-part-face_1,
  .upper-part-back_1 {
    text-align: center;
    background-color: white;
    color: #FFB237;
    border: 3px solid #000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 15px;
    border-radius: 40px 40px 0 0;
    font-weight: bold;
    z-index: 2;
    backface-visibility: hidden;
    transition: all 0.6s;
    font-size: 20px;
  }
  
  .upper-part-back_1 {
    background-color: #000;
    color: lightgray;
    transform: rotatex(180deg);
  }
  
  .lower-part_1 {
    width: 100%;
    height: 35%;
    border-radius: 0 0 40px 40px;
    position: relative;
    transform-style: preserve-3d;
    transform-origin: center top;
    transition: all 0.9s;
  }
  
  .lower-part-face_1,
  .lower-part-back_1 {
    background-color: #000;
    width: 100%;
    height: 100%;
    color: lightgray;
    font-weight: bold;
    font-size: 20px;
    color: #FFB237;
    position: absolute;
    border-radius: 0 0 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(0, -0.8px);
    backface-visibility: hidden;
    z-index: 2;
    text-align: center;
  }
  
  .lower-part-back_1 {
    backface-visibility: visible;
    /* border-radius: 40px; */
    color: #020202;
    background-color: lightgray;
    transform: rotateX(180deg);
    z-index: 1;
    transition: border-radius 0.6s;
  }
  
  .card_1:hover > .upper-part_1 {
    transform: rotatex(-0.5turn);
  }
  
  .card_1:hover > .lower-part_1 {
    transform: translateY(88.3px) rotateX(0.5turn);
  }
  
  .card_1:hover > .lower-part_1 > .lower-part-back_1 {
    border: 3px solid #FFB237;
    border-radius: 0 0 40px 40px;
  }
  