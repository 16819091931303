/* .specialization-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .specialization {
    margin-bottom: 40px;
  }
  
  .specialization h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .course-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .course-item {
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .course-item h3 {
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  .course-item p {
    margin: 0;
  }
  .specialization-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .specialization {
    margin-bottom: 40px;
  }
  
  .specialization h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .course-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .course-item {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 20px;
  }
  
  .course-item h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .course-item p {
    font-size: 14px;
    color: #666;
  } */
  .specialization-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .specialization {
    margin-bottom: 40px;
  }
  
  .specialization h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .course-grid {
    display: flex;
    gap: 20px;
  }
  
  .course-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .course-item {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 20px;
  }
  
  .course-item h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .course-item p {
    font-size: 14px;
    color: #666;
  }