/* .addMarksheetForm {
  width: 100%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.addMarksheetForm h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.formRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
  gap: 10px;
}

.formGroup {
  flex: 1 1 200px;
  margin-bottom: 10px;
}

.formGroup label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.formGroup input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.tableContainer {
  margin-top: 20px;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table th, table td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
}

table th {
  background-color: #f4f4f4;
}

.tableContainer input[type="text"],
.tableContainer input[type="number"] {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.buttonContainer {
  margin-top: 20px;
  text-align: center;
}

.buttonContainer1 {
  margin-top: 10px;
  text-align: center;
}

.buttonContainer button,
.buttonContainer1 button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.buttonContainer button:hover,
.buttonContainer1 button:hover {
  background-color: #FFB237;
} */


/* 
.add-marksheet-form {
  width: 90%;
  max-width: 700px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  margin: 20px auto;
  height: fit-content;
}

.add-marksheet-form h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.form-group {
  flex: 1 1 calc(50% - 10px);
  margin-bottom: 10px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.table-container {
  margin-top: 20px;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table th, table td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
}

table th {
  background-color: #f4f4f4;
}

.table-container input[type="text"],
.table-container input[type="number"] {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.button-container,
.button-container-1 {
  margin-top: 20px;
  text-align: center;
}

.button-container button,
.button-container-1 button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #ffb237;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button-container button:hover,
.button-container-1 button:hover {
  background-color: #e86e0a;
}

@media only screen and (min-width: 769px) {
     .responsive_add-marksheet{
        margin-top: -300px;
     }

      .resposive_s{
      display: none;
    }
}

@media (max-width: 768px) {
  .form-group {
    flex: 1 1 100%;
  }


} */
.add-marksheet-form {
  width: 100%;
  max-width: 900px;
  padding: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  margin: 10px auto;
  box-sizing: border-box;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.form-group {
  flex: 1 1 calc(25% - 10px);
  margin-bottom: 10px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 2px;
  font-size: 14px;
}

.form-group input {
  width: 100%;
  padding: 6px;
  font-size: 14px;
  border: 1px solid #000000;
  border-radius: 3px;
  box-sizing: border-box;
  color: #000000;
}

.table-container {
  margin-top: 15px;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table th, table td {
  padding: 8px;
  border: 1px solid #ccc;
  text-align: center;
  font-size: 14px;
}

table th {
  background-color: #f4f4f4;
}

.table-container input[type="text"], .table-container input[type="number"] {
  width: 100%;
  padding: 6px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
}

 .table-container input[type="number"] {
  width: 40%;
  padding: 6px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
}

.button-container, .button-container-1 {
  margin-top: 15px;
  display: inline-block;
}

.button-container button, .button-container-1 button {
  padding: 8px 16px;
  font-size: 14px;
  background-color: #ffb237;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button-container button:hover, .button-container-1 button:hover {
  background-color: #e86e0a;
}

@media only screen and (min-width: 769px) {
  .responsive_add-marksheet {
    margin-top: -300px;
  }

  .resposive_s {
    display: none;
  }
}

@media (max-width: 768px) {
  .form-group {
    flex: 1 1 calc(50% - 10px);
  }
  
  .table-container {
    overflow-x: scroll;
  }
  
  table {
    min-width: 500px;
  }
  
  .resposive_s {
    display: inline-block;
    font-weight: bold;
    margin-right: 5px;
  }
}