.responive_view_container{
    padding: '30px';
    background-color: '#fff'; 
    border-radius: '10px';
    max-width: '1200px'; 
    margin: '40px auto'; 
    font-family: 'Arial, sans-serif';
    margin-left:'300px';
  }

  

  @media screen and (max-width: 768px) {
    .responive_view_container{
        margin-left: 0px;
      }
  }