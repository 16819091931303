#customer-testimonials_1 .item_1 {
    text-align: center;
    padding: 50px;
    margin-bottom: 0px;
    opacity: 0.8;
    transform: scale(0.8);
    transition: all 0.3s ease-in-out;
  }
  
  #customer-testimonials_1 .item_1:hover {
    opacity: 1;
    transform: scale(1.2);
  }
  
  #customer-testimonials_1 .owl-item.active.center .item_1 {
    opacity: 1;
    transform: scale(1.2);
  }
  
  