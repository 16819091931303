
/* Style for search button */
.search-btn {
    background-color: #007bff; /* Blue color for button */
    color: #fff; /* White text color */
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .search-btn:hover {
    background-color: #FFB237; /* Darker shade of blue on hover */
  }
  
  .search-btn:focus {
    outline: none; /* Remove default focus outline */
  }