/* src/ContactForm.css */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px); /* Add blur effect */
    display: none;
    justify-content: center;
    align-items: center;
   
  }
  .popup{
    z-index: 999;
    margin-left: auto;
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    height: 100vh;
    background-color: #5555505d;
    margin-right: auto;
    position: fixed;
  }
  .innerpopup{
    margin-top: 4vh;
    flex-direction: column;
  }
  .popupbutton{
    position: absolute;
    top: 12%;
    left: 59%;
    font-size: 24px;
    color: #000000;
    background-color: #00000000;

  }
  .overlay.show {
    display: flex;
  }
  
  .contact-form-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgb(0, 0, 0);
    padding: 0;
    border-radius: 10px; /* Increased border-radius for rounded corners */
    display: none;
    /* z-index: 1001; */
    width: 90%;
    color: white;
    max-width: 800px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Deeper shadow for more depth */
  }
  
  .contact-form-popup.show {
    display: block;
  }
  
  .contact-form-container {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 90vh; /* Ensures the popup doesn't exceed the viewport height */
  }
  /* 
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
  
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  } */
  
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 5%;
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
    z-index: 1002; /* Ensure it's above the overlay */
  }
  
  
  
  .form-content {
    display: flex;
    flex-direction: column; /* Stack vertically for mobile view */
    width: 100%;
  }
  
  .form-left,
  .form-right {
    width: 100%; /* Take full width for mobile view */
    margin-bottom: 20px;
  }
  
  .form-left {
    padding: 30px;
  }
  
  .form-right {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;
  }
  
  .form-right img {
    width: 90%;
    height: 80%; /* Adjust height to keep aspect ratio */
    border-radius: 10px;
  }
  
  .social-icons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .social-icons a {
    color: rgb(0, 0, 0);
    font-size: 40px;
    margin-bottom: 10px;
    transition: color 0.3s;
  }
  
  .social-icons a:hover {
    color: #0554f2; /* Change to the color you prefer */
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  form input,
  form textarea {
    margin-bottom: 15px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
    font-size: 16px;
    box-sizing: border-box;
    transition: all 0.3s ease; /* Smooth transition for focus effect */
    background-color: #f9f9f9;
  }
  
  form input:focus,
  form textarea:focus {
    border-color: #333;
    outline: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Shadow effect on focus */
    background-color: #fff;
  }
  
  form button {
    background-color: #333;
    color: white;
    padding: 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  form button:hover {
    background-color: #555;
  }
  
  h2 {
    margin-bottom: 20px;
    text-align: left;
    font-size: 24px; /* Larger font size */
  }
  
  @media (min-width: 768px) {
    .form-content {
      flex-direction: row; /* Side by side on larger screens */
    }
  
    .form-left,
    .form-right {
      width: 50%;
      margin-bottom: 0;
    }
  }
  